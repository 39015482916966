import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultationStatus } from '@enums/consultation-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationTypes } from '@enums/consultation-types';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { OrderUpsell } from '@models/order-upsell';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { NavigationService } from '@services/navigation.service';
import { OrderService } from '@services/order.service';
import { ScheduleOnceService } from '@services/schedule-once.service';
import { SessionStorageService } from '@services/session-storage.service';
import { TreatmentConsultationQuestionnaireService } from '@services/treatment-consultation-questionnaire.service';
import { catchError, concatMap, from, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-treatment-consultation-submit',
  templateUrl: './treatment-consultation-submit.component.html',
  styleUrls: ['./treatment-consultation-submit.component.scss'],
})
export class TreatmentConsultationSubmitComponent {
  @Input() attachment: File;
  @Input() disabled: boolean = false;

  isLoading: boolean = false;
  displayError: boolean = false;
  consultationType: ConsultationTypes;
  scheduleOnceId: string;
  email: string = this.config.email;

  /**
   * Determines if the UTI urine test kit upsell should be added.
   */
  get shouldAddUtiUrineTestKitUpsell(): boolean {
    return (
      this.sessionStorageService.treatmentType === ConsultationTreatmentTypes.Uti &&
      this.treatmentConsultationQuestionnaireService.getQuestionAnswer(this.config.healthInsuranceQuestionId) ===
        QuestionnaireAnswers.Yes
    );
  }

  constructor(
    private consultationRequestService: ConsultationRequestService,
    private treatmentConsultationQuestionnaireService: TreatmentConsultationQuestionnaireService,
    private sessionStorageService: SessionStorageService,
    private orderService: OrderService,
    private router: Router,
    private scheduleOnceService: ScheduleOnceService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private navigationService: NavigationService
  ) {}

  /**
   * Submit the consultation and store the necessary data.
   */
  submit(): void {
    this.disabled = true;
    this.isLoading = true;
    this.storeAttachment()
      .pipe(
        concatMap(() =>
          from(this.addExtraUpsells().length > 0 ? this.addExtraUpsells() : [of(null)]).pipe(
            concatMap((upsellObservable) => upsellObservable),
            catchError(() => of(null))
          )
        ),
        concatMap(() => this.updateCustomerAddress().pipe(catchError(() => of(null)))),
        concatMap(() => this.updateConsultation()),
        concatMap((consultationResponse) => {
          this.consultationType = consultationResponse.type;
          this.scheduleOnceId = consultationResponse.schedule_once_id;

          return of(consultationResponse);
        })
      )
      .subscribe({
        next: () => {
          this.handleConsultationResponse();
        },
        error: this.captureTMUConsultationRequestException.bind(this),
      });
  }

  /**
   * Adds extra upsells to the order.
   */
  addExtraUpsells(): Observable<any>[] {
    const upsellRequests: Observable<any>[] = [];

    if (this.shouldAddUtiUrineTestKitUpsell) {
      upsellRequests.push(this.addUtiUrineTestKitUpsell());
    }

    return upsellRequests;
  }

  /**
   * Stores the UTI urine test kit upsell and the shipping address.
   */
  addUtiUrineTestKitUpsell(): Observable<any> {
    const urineTestKitUpsell = {
      id: this.config.consultationRequestUpsells[UpsellSlugs.UtiUrineTestKit].id,
    } as OrderUpsell;

    return this.orderService
      .storeUpsell(urineTestKitUpsell, this.sessionStorageService.transactionId, this.sessionStorageService.hash)
      .pipe(
        switchMap((testId: number) =>
          this.orderService.storeShippingAddressForTests(
            UpsellSlugs.UtiUrineTestKit,
            [testId],
            this.sessionStorageService.transactionId,
            this.sessionStorageService.hash
          )
        )
      );
  }

  /**
   * Stores the customer address.
   */
  updateCustomerAddress(): Observable<any> {
    return this.orderService.updateAddress(
      this.sessionStorageService.address,
      this.sessionStorageService.transactionId,
      this.sessionStorageService.hash
    );
  }

  /**
   * Updates the consultation.
   */
  updateConsultation(): Observable<any> {
    return from(this.treatmentConsultationQuestionnaireService.getConsultationRequestPayload()).pipe(
      switchMap((consultationRequestPayload) =>
        this.consultationRequestService.update(
          this.sessionStorageService.consultationId,
          this.sessionStorageService.transactionId,
          this.sessionStorageService.hash,
          consultationRequestPayload
        )
      )
    );
  }

  /**
   * Stores the attachment.
   */
  storeAttachment(): Observable<any> {
    return this.consultationRequestService.storeAttachment(
      this.sessionStorageService.transactionId,
      this.sessionStorageService.hash,
      this.sessionStorageService.consultationId,
      this.attachment
    );
  }

  /**
   * Handles the consultation update response.
   */
  private handleConsultationResponse(): void {
    if (
      this.consultationType === ConsultationTypes.Scheduled &&
      this.sessionStorageService.consultationStatus === ConsultationStatus.Pending
    ) {
      this.router.navigateByUrl(
        this.scheduleOnceService.getScheduleConsultationUrl(
          this.sessionStorageService.consultationId,
          this.scheduleOnceId,
          this.sessionStorageService.transactionId,
          this.sessionStorageService.hash,
          this.sessionStorageService.patient.phone
        )
      );

      return;
    }

    this.navigationService.navigateToNextConsultationRequestPage(this.sessionStorageService.treatmentType);
  }

  /**
   * Captures the exception when finishing the consultation request for a TMU order.
   *
   * @param {Error} error The error that cause the process to fail.
   */
  private captureTMUConsultationRequestException(error?: Error): void {
    let attachment = {};
    let customError = new Error('Failed when finishing the consultation request for a TMU order.');

    if (error) {
      customError.stack = error.stack;
    }

    if (this.attachment) {
      attachment = {
        name: this.attachment.name,
        size: this.attachment.size,
        type: this.attachment.type,
      };
    }

    console.error(customError, {
      transactionId: this.sessionStorageService.transactionId ?? 'transactionId not found',
      hash: this.sessionStorageService.hash ?? 'hash not found',
      consultationId: this.sessionStorageService.consultationId ?? 'consultationId not found',
      attachment,
      ...{ context_info: error },
    });

    this.isLoading = false;
    this.displayError = true;
  }
}
