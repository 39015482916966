export class Questionnaire {
  id: string;
  medicalQuestionIds: string[];
  navigationUrls: string[];
  upsellIds: string[];
  mentalQuestionIds: string[];

  constructor(options: {
    id: string;
    medicalQuestionIds?: string[];
    navigationUrls?: string[];
    upsellIds?: string[];
    mentalQuestionIds?: string[];
  }) {
    this.id = options.id;
    this.medicalQuestionIds = options.medicalQuestionIds || [];
    this.navigationUrls = options.navigationUrls || [];
    this.upsellIds = options.upsellIds || [];
    this.mentalQuestionIds = options.mentalQuestionIds || [];
  }
}
