import { Component } from '@angular/core';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-vitamin-b12',
  templateUrl: './vitamin-b12.component.html',
})
export class VitaminB12Component {
  readonly slug: UpsellSlugs = UpsellSlugs.VitaminB12Supplement;

  constructor() {}
}
