export class Address {
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipcode: string;

  constructor(address: Address = null) {
    if (address) {
      Object.assign(this, address);
    }
  }

  public static statesUS = [
    { name: 'Alabama', abbv: 'AL' },
    { name: 'Alaska', abbv: 'AK' },
    { name: 'Arizona', abbv: 'AZ' },
    { name: 'Arkansas', abbv: 'AR' },
    { name: 'California', abbv: 'CA' },
    { name: 'Colorado', abbv: 'CO' },
    { name: 'Connecticut', abbv: 'CT' },
    { name: 'Delaware', abbv: 'DE' },
    { name: 'District of Columbia', abbv: 'DC' },
    { name: 'Florida', abbv: 'FL' },
    { name: 'Georgia', abbv: 'GA' },
    { name: 'Hawaii', abbv: 'HI' },
    { name: 'Idaho', abbv: 'ID' },
    { name: 'Illinois', abbv: 'IL' },
    { name: 'Indiana', abbv: 'IN' },
    { name: 'Iowa', abbv: 'IA' },
    { name: 'Kansas', abbv: 'KS' },
    { name: 'Kentucky', abbv: 'KY' },
    { name: 'Louisiana', abbv: 'LA' },
    { name: 'Maine', abbv: 'ME' },
    { name: 'Maryland', abbv: 'MD' },
    { name: 'Massachusetts', abbv: 'MA' },
    { name: 'Michigan', abbv: 'MI' },
    { name: 'Minnesota', abbv: 'MN' },
    { name: 'Mississippi', abbv: 'MS' },
    { name: 'Missouri', abbv: 'MO' },
    { name: 'Montana', abbv: 'MT' },
    { name: 'Nebraska', abbv: 'NE' },
    { name: 'Nevada', abbv: 'NV' },
    { name: 'New Hampshire', abbv: 'NH' },
    { name: 'New Jersey', abbv: 'NJ' },
    { name: 'New Mexico', abbv: 'NM' },
    { name: 'New York', abbv: 'NY' },
    { name: 'North Carolina', abbv: 'NC' },
    { name: 'North Dakota', abbv: 'ND' },
    { name: 'Ohio', abbv: 'OH' },
    { name: 'Oklahoma', abbv: 'OK' },
    { name: 'Oregon', abbv: 'OR' },
    { name: 'Pennsylvania', abbv: 'PA' },
    { name: 'Puerto Rico', abbv: 'PR' },
    { name: 'Rhode Island', abbv: 'RI' },
    { name: 'South Carolina', abbv: 'SC' },
    { name: 'South Dakota', abbv: 'SD' },
    { name: 'Tennessee', abbv: 'TN' },
    { name: 'Texas', abbv: 'TX' },
    { name: 'Utah', abbv: 'UT' },
    { name: 'Vermont', abbv: 'VT' },
    { name: 'Virginia', abbv: 'VA' },
    { name: 'Washington', abbv: 'WA' },
    { name: 'West Virginia', abbv: 'WV' },
    { name: 'Wisconsin', abbv: 'WI' },
    { name: 'Wyoming', abbv: 'WY' },
  ];

  public static statesCA = [
    { name: 'Ontario', abbv: 'ON' },
    { name: 'Quebec', abbv: 'QC' },
    { name: 'British Colombia', abbv: 'BC' },
    { name: 'Alberta', abbv: 'AB' },
    { name: 'Manitoba', abbv: 'MB' },
    { name: 'Saskatchewan', abbv: 'SK' },
    { name: 'Nova Scotia', abbv: 'NS' },
    { name: 'New Brunswick', abbv: 'NB' },
    { name: 'Newfoundland and Labrador', abbv: 'NL' },
    { name: 'Prince Edward Island', abbv: 'PE' },
    { name: 'Northwest Territories', abbv: 'NT' },
    { name: 'Yukon', abbv: 'YT' },
    { name: 'Nunavut', abbv: 'NU' },
  ];

  public static statesIds = {
    AL: 1,
    AK: 2,
    AZ: 3,
    AR: 4,
    CA: 5,
    CO: 6,
    CT: 7,
    DE: 8,
    DC: 51,
    FL: 9,
    GA: 10,
    HI: 11,
    ID: 12,
    IL: 13,
    IN: 14,
    IA: 15,
    KS: 16,
    KY: 17,
    LA: 18,
    ME: 19,
    MD: 20,
    MA: 21,
    MI: 22,
    MN: 23,
    MS: 24,
    MO: 25,
    MT: 26,
    NE: 27,
    NV: 28,
    NH: 29,
    NJ: 30,
    NM: 31,
    NY: 32,
    NC: 33,
    ND: 34,
    OH: 35,
    OK: 36,
    OR: 37,
    PA: 38,
    PR: 52,
    RI: 39,
    SC: 40,
    SD: 41,
    TN: 42,
    TX: 43,
    UT: 44,
    VT: 45,
    VA: 46,
    WA: 47,
    WV: 48,
    WI: 49,
    WY: 50,
  };
}
