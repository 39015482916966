import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CartAdditionalItem } from '@models/cart-additional-item';
import { Coupon } from '@models/coupon';
import { Discount } from '@models/discount';
import { ExtraCharge } from '@models/extra-charge';
import { Fee } from '@models/fee';
import { OrderUpsell } from '@models/order-upsell';
import { Test } from '@models/test';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { HealthLabsOrderService } from '@services/healthlabs-order.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
  giftCardAppliedAmount = 0;
  tests: Test[] = [];
  isLoading: boolean = false;
  siteName: string = this.config.domain;

  private subscriptions: Subscription[] = [];

  /**
   * Get the site URL.
   */
  get testsUrl(): string {
    return this.config.siteUrls.tests;
  }

  /**
   * Get the order total.
   */
  get total(): number {
    return this.order.getTotal() - this.giftCardAppliedAmount;
  }

  /**
   * Gets the order subtotal.
   */
  get subtotal(): number {
    return this.total - this.order.getExtraChargesTotal() - this.order.getOrderUpsellsTotal();
  }

  /**
   * Get the order fee.
   */
  get fee(): Fee {
    return this.storage.fee;
  }

  /**
   * Get the order coupon.
   */
  get coupon(): Coupon {
    return this.storage.coupon;
  }

  /**
   * Get the order discount.
   */
  get discount(): Discount {
    return this.storage.discount;
  }

  /**
   * Gets the order extra charges.
   */
  get extraCharges(): ExtraCharge[] {
    return this.order.extraCharges;
  }

  /**
   * Gets the order upsells.
   */
  get orderUpsells(): OrderUpsell[] {
    return this.order.orderUpsells;
  }

  /**
   * Returns true when the remove tag button should be displayed
   */
  get shouldDisplayRemoveTag(): boolean {
    return this.domainService.isHealthlabsDomain();
  }

  /**
   * Gets the cart additional items.
   */
  get cartAdditionalItems(): CartAdditionalItem[] {
    return this.config.orderSummary.cartAdditionalItems;
  }

  /**
   * Gets whether the cart summary table headers should be displayed.
   */
  get displayCartSummaryTableHeaders(): boolean {
    return this.config.orderSummary.displayCartSummaryTableHeaders;
  }

  /**
   * Gets the text that should precede the name of the tests in the cart.
   */
  get testsPrefix(): string {
    return this.config.orderSummary.testsPrefix;
  }

  /**
   * Get the coupon item name which will be displayed in order summary section
   */
  get couponItemName(): string {
    return this.coupon.coupon_name + (this.config.showCouponCodeInSummary ? ' (' + this.coupon.coupon_code + ')' : '');
  }

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    public storage: StorageService,
    private order: OrderService,
    public domainService: DomainService
  ) {}

  /**
   * Initialize the component.
   */
  ngOnInit(): void {
    this.tests = this.storage.tests;
    this.subscriptions.push(
      this.storage.testsSubject.subscribe((tests: Test[]) => (this.tests = tests)),
      this.order.giftCardAppliedSubject$.subscribe((amount: number) => this.onGiftCardApplied(amount))
    );

    if (this.order.reorderData) {
      return;
    }

    this.order.loadCart().subscribe();
  }

  /**
   * Unsubscribe on component destroy.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Whether the order summary edit button should be displayed.
   */
  get shouldDisplayEditButton(): boolean {
    return !this.storage.free && this.config.orderSummary.allowEditingCart;
  }

  /**
   * The text to be displayed on the edit cart button.
   */
  get editCartButtonText(): string {
    return this.config.orderSummary.editCartButtonText;
  }

  /**
   * Get the coupon total amount
   */
  getTotalCoupons(): number {
    return this.order.getTotalCoupons(this.order.getTestsTotal() + this.order.getPartnerTotal());
  }

  /**
   * Callback when the gift card applied subject is fired.
   *
   * @param {number} appliedTotal the amount of the gift card that is applied
   */
  onGiftCardApplied(appliedTotal: number): void {
    this.giftCardAppliedAmount = appliedTotal;
  }

  /**
   * Returns true when has partner checkbox is checked
   */
  get hasPartner(): boolean {
    return this.storage.hasPartner;
  }

  /**
   * Trigger action to remove a test from the cart on HL
   *
   * @param {Test} test the test to be removed
   */
  removeTest(test: Test): void {
    this.toggleLoading(true);
    (this.order as HealthLabsOrderService).removeTestFromCart(test.entry_id).subscribe({
      next: () => {
        if (this.tests.length === 1) {
          this.toggleLoading(false);
          this.order.clearLastOrder();
          window.location.href = this.config.siteUrls.tests;

          return;
        }

        this.order.loadCart([], { hasInHomeCollection: this.storage.hasInHomeCollection }).subscribe({
          next: () => this.toggleLoading(false),
          error: () => this.toggleLoading(false),
        });
      },
      error: () => this.toggleLoading(false),
    });
  }

  /**
   * Toggle loading state.
   *
   * @param {boolean} isLoading Loading state.
   */
  toggleLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.order.placeOrderButtonDisable = isLoading;
  }

  /**
   * Returns true when the multiple discount alert should be displayed
   */
  get shouldDisplayMultipleDiscountAlert(): boolean {
    return this.domainService.isHealthlabsDomain() && this.tests?.length === 1 && !this.storage.isBaOrder;
  }

  /**
   * Set display modal state variable to true when the multiple discount modal should be displayed
   */
  displayFindTestsModal(): void {
    if (!this.config.displayFindTestsModal) {
      return;
    }

    (this.order as HealthLabsOrderService).findTestsModalState = true;
  }

  /**
   * Gets the amount of cents a in home collection costs.
   */
  get inHomeCollectionCostInCents(): number {
    return this.config.inHomeCollectionCostInCents;
  }
}
