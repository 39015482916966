import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomURLEncoder } from '@models/custom-url-encoder';
import { LoadCartRequest } from '@models/load-cart-request';

export class HealthLabsLoadCartRequest extends LoadCartRequest {
  labId?: number;
  tests?: string[];
  coupon?: string;
  accountToken?: string;
  hasInHomeCollection?: boolean;

  /**
   * Build query params object for load cart url for HealthLabs.
   *
   * @returns { HttpParams } the query params object for load cart url.
   **/
  override getHttpParams(): HttpParams {
    let params = new HttpParams({ encoder: new CustomURLEncoder() });

    if (this.labId) {
      params = params.append('labId', this.labId);
    }

    if (this.coupon) {
      params = params.append('coupon', this.coupon);
    }

    if (this.accountToken) {
      params = params.append('accountToken', this.accountToken);
    }

    if (this.tests && this.tests.length) {
      this.tests.forEach((test) => (params = params.append('tests[]', test)));
    }

    if (this.hasInHomeCollection) {
      params = params.append('hasInHomeCollection', this.hasInHomeCollection);
    }

    return params;
  }

  /**
   * Gets the required HTTP headers for the load-cart request to Healthlabs.
   */
  override getHttpHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Browser-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }
}
