import { InputTypes } from '@enums/input-types';
import { LogicalOperators } from '@enums/logical-operators';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { QuestionValidator } from '@models/dynamic-forms/question-validator';
import { QuestionVisibility } from '@models/dynamic-forms/question-visibility';
import { QuestionWarning } from '@models/dynamic-forms/question-warning';
import { SelectableOption } from '@models/selectable-option';

export class Question {
  id: string;
  type: InputTypes;
  value: any;
  label: string;
  extraStorageKey: string;
  validators: QuestionValidator[];
  options: SelectableOption[];
  visibility: QuestionVisibility;
  warnings: QuestionWarning[];
  hint: string;
  placeholder: string;

  constructor(
    options: {
      id: string;
      type: InputTypes;
      value?: any;
      label?: string;
      extraStorageKey?: string;
      validators?: QuestionValidator[];
      options?: SelectableOption[];
      visibility?: QuestionVisibility;
      warnings?: QuestionWarning[];
      hint?: string;
      placeholder?: string;
    },
    externalData?: ExternalData
  ) {
    this.id = options.id;
    this.type = options.type;
    this.value = options.value;
    this.label = options.label ? this.replacePlaceholders(options.label, externalData || {}) : '';
    this.extraStorageKey = options.extraStorageKey || '';
    this.validators = options.validators || [];
    this.options = options.options || [];
    this.visibility = options.visibility || {
      externalConditions: {
        conditions: [],
        logicalOperator: LogicalOperators.And,
      },
      triggerQuestions: {
        questions: [],
        logicalOperator: LogicalOperators.And,
      },
      logicalOperator: LogicalOperators.And,
    };
    this.warnings = options.warnings ? this.processWarnings(options.warnings, externalData || {}) : [];
    this.hint = options.hint ? this.replacePlaceholders(options.hint, externalData || {}) : '';
    this.placeholder = options.placeholder || '';
  }

  /**
   * Replaces placeholders in the given text with values from the provided external data.
   *
   * Placeholders are enclosed in double curly braces (e.g., {{placeholder}}).
   *
   * @param {string} text the string containing placeholders to be replaced
   * @param {ExternalData} values an object containing the values to replace the placeholders
   *
   * @returns {string} the string with placeholders replaced by their corresponding values, or unchanged placeholders
   * if no match is found
   */
  private replacePlaceholders(text: string, values: ExternalData): string {
    return text.replace(/{{([a-zA-Z0-9_]+)}}/g, (_, key) => {
      const value = values[key.trim()];

      return typeof value === 'string' ? value : `{{${key}}}`;
    });
  }

  /**
   * Processes an array of QuestionWarning, replacing placeholders in each warning message.
   *
   * @param {QuestionWarning[]} warnings the warnings array
   * @param {ExternalData} values the values to replace the placeholders
   *
   * @returns {QuestionWarning[]} the processed warnings with placeholders replaced
   */
  private processWarnings(warnings: QuestionWarning[], values: ExternalData): QuestionWarning[] {
    return warnings.map((warning) => ({
      ...warning,
      message: this.replacePlaceholders(warning.message, values),
    }));
  }
}
