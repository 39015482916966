import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SelectableOption } from '@models/selectable-option';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-medication-pickup-delivery',
  templateUrl: './medication-pickup-delivery.component.html',
  styleUrls: ['./medication-pickup-delivery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MedicationPickupDeliveryComponent implements AfterViewInit, OnDestroy {
  @ViewChild('upsellCard', { static: false }) upsellCard: UpsellCardComponent;

  readonly slug: UpsellSlugs = UpsellSlugs.DeliverMedication;
  readonly options: SelectableOption[] = [
    new SelectableOption('Yes, overnight my medicine', this.slug),
    new SelectableOption('No, I will pickup from my Pharmacy', 'No'),
  ];
  readonly hints: string[] = [
    `This option requires an additional $${
      this.config.consultationRequestUpsells[this.slug].price
    } shipping and handling charge.`,
    'Overnight shipping is available Monday - Friday 12AM - 4PM CT. If your order was placed over the weekend, your medication will ship first thing Monday.',
  ];

  addressForm: FormGroup | null = null;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    private sessionStorageService: SessionStorageService
  ) {}

  /**
   * Sets up the address form based on the upsell option selected after the view has been initialized.
   */
  ngAfterViewInit(): void {
    this.setAddressForm(this.upsellCard.control.value);
    this.changeDetectorRef.detectChanges();
    this.listenForUpsellOptionChanges();
  }

  /**
   * Unsubscribes from all subscriptions when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * Retrieves additional forms to validate if the address form is enabled.
   */
  get additionalFormsToValidate(): FormGroup[] {
    return this.addressForm ? [this.addressForm] : [];
  }

  /**
   * Stores the shipping address in session storage if delivery is selected.
   *
   * @param {string} optionSelected the selected upsell option
   */
  onContinueButtonPressed(optionSelected: string): void {
    this.sessionStorageService.shippingAddress = optionSelected === this.slug ? this.addressForm.value : null;
  }

  /**
   * Subscribes to changes in the upsell option and updates the address form based on selection.
   */
  private listenForUpsellOptionChanges(): void {
    this.subscriptions.push(
      this.upsellCard.control.valueChanges.subscribe((value: string) => this.setAddressForm(value))
    );
  }

  /**
   * Configures the address form based on the current upsell option value.
   *
   * @param {string} upsellControlValue the selected upsell control value
   */
  private setAddressForm(upsellControlValue: string): void {
    this.addressForm = upsellControlValue === this.slug ? this.formService.address : null;
    this.addressForm?.addControl('streetAddress2', new FormControl(null));
    this.patchAddressForm();
  }

  /**
   * Patches the address form with the shipping address stored in session storage.
   */
  private patchAddressForm(): void {
    const address = this.sessionStorageService.shippingAddress || this.sessionStorageService.address;

    if (!this.addressForm || !address) {
      return;
    }

    this.addressForm.patchValue(address);
  }
}
