import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Gender } from '@enums/gender';
import { UpsellSlugs } from '@enums/upsell-slugs';

/**
 * Environment variables for use on the Starfish site, both in production and local environment.
 */
export const starfishCommonEnvironment = {
  domain: 'Starfish.com',
  ngFindalab: {
    searchEndpoint: '/pharmacies/labs/nearPostalCode',
    filterByStates: null,
    showPhone: true,
    filterByTestAndSite: false,
    searchTitle: 'Enter Your Location',
    searchDescription:
      'Please choose a nearby pharmacy where our doctor can send your prescription once they have reviewed your medical information and your order has been placed.',
    pharmacySearchDescription:
      'Please choose a nearby pharmacy where our doctor can send your prescription once they have reviewed your medical information and your order has been placed.',
    locateMsg: 'Use my current location',
    labButtonText: 'Choose This Pharmacy',
    searchButtonText: 'Find',
    showSearchIcon: false,
    labIconClass: 'fa fa-arrow-right',
    searchMessageNoResults:
      'Oops! Sorry, we could not find any pharmacies near that location. Please try your search again with a different or less specific address.',
    inputGroup: {
      container: '',
      field: null,
      button: '',
    },
    resultsZoom: 12,
    pharmacyDescription: false,
    choosePharmacySearchTitle: 'Enter Your Location',
    showDayOfWeekFilter: false,
  },
  titles: {
    order: 'Checkout - Starfish',
    consultationRequest: 'Questionnaire - Starfish',
    headlineCheckoutScheduled: '',
    step1Title: 'Find a Pharmacy',
    selectedCenterLabel: 'Pharmacy',
    orderPageHeader: {
      title: "You're almost <strong>Done!</strong>",
      subtitle:
        'Thank you for selecting a pharmacy. We just need a few more details before determining your diagnosis.',
    },
    scheduledConsultationRequest: {
      title: 'Clinician Phone Consultation Needed',
      calendarDetails:
        'To ensure compliance with Telehealth regulations in your state, we must arrange a brief phone consultation with one of our clinicians before prescribing medication. This consultation is provided at no additional cost to you.',
      phoneNumberDetails: 'A Clinician will call you at',
      availabilityDetails:
        "Typically, our clinicians call earlier than the anticipated window, so there's no need to be concerned if your appointment appears to be scheduled further than expected.",
    },
  },
  buttons: {
    checkout: 'Place Order',
    selectedCenterChange: 'Change Pharmacy',
  },
  order: {
    allowedTreatmentGenders: {
      bacterial_vaginosis: Gender.Female,
      uti: Gender.Female,
      vaginal_dryness: Gender.Female,
      yeast_infection: Gender.Female,
    },
    minAgeAllowed: 18,
  },
  styles: {
    chooseAPharmacy: {
      labButtonClass: 'is-secondary-orange has-text-white',
      searchButtonClass: 'is-green has-text-white',
      googleMapsLabMarkerFillColor: '#fa676b',
      googleMapsRecommendedMarkerFillColor: '#fdd8d9',
      googleMapsMarkerHoverFillColor: '#f84f55',
    },
    consultationRequest: {
      headlineClass: 'column is-flex is-justify-content-center pt-5 pb-0 has-text-weight-semibold',
    },
    healthLabsCoupon: {
      buttonColor: 'secondary-orange',
    },
    orderCompleteInstructions: {
      cardContentClass: '',
    },
    orderBetterLab: {
      orderPlaceNotificationClass: 'is-warning',
      currentTestCenterNotificationClass: 'is-info',
      keepCurrentLabButtonClass: 'is-uppercase',
      keepCurrentLabButtonColor: 'secondary-orange',
    },
    testCenter: {
      labButtonClass: '',
      searchButtonClass: 'is-secondary-orange has-text-white',
      googleMapsLabMarkerFillColor: '#fa676b',
      googleMapsRecommendedMarkerFillColor: '#fdd8d9',
      googleMapsMarkerHoverFillColor: '#f84f55',
    },
  },
  shopperApproved: {
    site: 40107,
    token: '330wDBfS',
  },
  orderCompleteSummary: {
    testOrderedWording: {
      single: 'Preferred Medication/Treatment',
      plural: 'Preferred Medications/Treatments',
    },
  },
  enablePayLater: false,
  enableBitPay: false,
  enableOrderUpgrade: false,
  enableOrderMedicalHistory: true,
  enableTosOnOrderPage: true,
  forceEmailResults: true,
  faviconFile: 'starfish-favicon.ico',
  inHomeCollectionAllowed: false,
  displayFindTestsModal: false,
  displaySisterSiteBanner: false,
  phone: '1-866-760-3593',
  email: 'support@starfish.com',
  validateSelectedLabNetwork: false,
  getNetworksFromTests: false,
  verifyTestsCanBeOrdered: false,
  isPartnerTestingAllowed: false,
  orderSummary: {
    cartAdditionalItems: [
      { description: 'Local Pharmacy Pickup', price: '$0.00' },
      {
        description: 'Doctor Review',
        tooltip: 'Our Physicians will review your medical information and contact you with your prescription.',
        price: 'Included',
      },
    ],
    allowEditingCart: false,
    editCartButtonText: 'Edit',
    displayCartSummaryTableHeaders: false,
    testsPrefix: 'Medication Online',
  },
  allowedRoutes: [
    '/null',
    '/order',
    '/order-complete.php',
    '/consultation/vaginal-cream',
    '/consultation/bacterial-vaginosis/request',
    '/consultation/uti/request',
    '/consultation/std/request',
    '/consultation/hsv/request',
    '/consultation/yeast-infection/request',
    '/consultation/std-prevention/request',
    '/consultation/metformin/request',
    '/consultation/glp-1/request',
    '/consultation/yeast-infection-prevention',
    '/consultation/alleviate-nausea-and-vomiting',
    '/consultation/replenish-your-good-bacteria',
    '/consultation/be-prepared-for-the-unexpected',
    '/consultation/need-a-doctors-note',
    '/consultation/relieve-outbreak-pain',
    '/consultation/prevent-vitamin-b12-deficiency',
    '/consultation/medication-pickup-or-delivery',
    '/consultation/upload-consult-attachments',
    '/consultation/schedule-consultation',
    '/consultation/clotrimazole-vaginal-cream',
    '/consultation/mens-intimate-wash',
    '/consultation/follow-up-std-test',
    '/consultation/payment-info',
    '/consultation/vitamin-d-supplement',
    '/yeast-infection/consultation-request-completed',
    '/bacterial-vaginosis/consultation-request-completed',
    '/uti/consultation-request-completed',
    '/std/consultation-request-completed',
    '/std-prevention/consultation-request-completed',
    '/hsv/consultation-request-completed',
    '/metformin/consultation-request-completed',
    '/glp-1/consultation-request-completed',
  ],
  showCouponCodeInSummary: false,
  consultationRequestUpsells: {
    [UpsellSlugs.Pyridium]: {
      price: 20,
      id: 2718,
    },
    [UpsellSlugs.FollowUpUrineTest]: {
      price: 29,
      id: 2836,
    },
    [UpsellSlugs.Fluconazole]: {
      price: 20,
      id: 2719,
    },
    [UpsellSlugs.FluconazoleForBVTreatment]: {
      price: 25,
      id: 2890,
    },
    [UpsellSlugs.Metronidazole]: {
      price: 25,
      id: 2792,
    },
    [UpsellSlugs.Probiotics]: {
      price: 10,
      id: 2720,
    },
    [UpsellSlugs.ShippedProbiotics]: {
      price: 10,
      id: 2991,
    },
    [UpsellSlugs.DoctorsNote]: {
      price: 10,
      id: 2721,
    },
    [UpsellSlugs.Levonorgestrel]: {
      price: 10,
      id: 2722,
    },
    [UpsellSlugs.DeliverMedication]: {
      price: 39,
      id: 2723,
    },
    [UpsellSlugs.OndansetronPill]: {
      price: 20,
      id: 2834,
    },
    [UpsellSlugs.OndansetronDissolvableTablet]: {
      price: 20,
      id: 2835,
    },
    [UpsellSlugs.MetronidazoleVaginalGel]: {
      price: 25,
      id: 2794,
    },
    [UpsellSlugs.ClindamycinVaginalCream]: {
      price: 25,
      id: 2801,
    },
    [UpsellSlugs.ClotrimazoleVaginalCream]: {
      price: 15,
      id: 2845,
    },
    [UpsellSlugs.MensIntimateWash]: {
      price: 29,
      id: 2902,
    },
    [UpsellSlugs.FollowUpStdTest]: {
      price: 39,
      id: 2967,
    },
    [UpsellSlugs.PartnerTreatment]: {
      price: 39,
      id: 2982,
    },
    [UpsellSlugs.LidocaineOintment]: {
      price: 29,
      id: 3071,
    },
    [UpsellSlugs.VitaminB12Supplement]: {
      price: 10,
      id: 3101,
    },
    [UpsellSlugs.VitaminDSupplement]: {
      price: 10,
      id: 3066,
    },
    [UpsellSlugs.MetforminExtendedRelease]: {
      price: 25,
      id: 3067,
    },
    [UpsellSlugs.AcyclovirCreamHSV]: {
      price: 20,
      id: 3069,
    },
    [UpsellSlugs.AcyclovirCream]: {
      price: 29,
      id: 3018,
    },
    [UpsellSlugs.UtiUrineTestKit]: {
      price: 0,
      id: 3078,
    },
  },
  findalabMapId: '8f1673661122dda0',
  maxAgeAllowed: 81,
  treatmentFormSettings: {
    [ConsultationTreatmentTypes.Uti]: '1',
    [ConsultationTreatmentTypes.YeastInfection]: '2',
    [ConsultationTreatmentTypes.BacterialVaginosis]: '3',
    [ConsultationTreatmentTypes.StdPrevention]: '4',
    [ConsultationTreatmentTypes.Std]: null,
    [ConsultationTreatmentTypes.StarfishStd]: '5',
    [ConsultationTreatmentTypes.Hsv]: '7',
    [ConsultationTreatmentTypes.Metformin]: '8',
    [ConsultationTreatmentTypes.Glp1]: '9',
  },
};
