export enum UpsellSlugs {
  Pyridium = 'Pyridium',
  FollowUpUrineTest = 'Follow-Up Urine Test',
  Fluconazole = 'Fluconazole (Yeast Prevention)',
  FluconazoleForBVTreatment = 'Fluconazole (BV)',
  Probiotics = 'Probiotics',
  ShippedProbiotics = 'Shipped Probiotics',
  DoctorsNote = "Doctor's Note",
  Levonorgestrel = 'Levonorgestrel (Plan B)',
  DeliverMedication = 'Delivery',
  OndansetronPill = 'Zofran (Ondansetron) Pill',
  OndansetronDissolvableTablet = 'Zofran (Ondansetron) Dissolvable Tablet',
  MensIntimateWash = 'Mens Intimate Wash',
  MetronidazoleVaginalGel = 'Metronidazole Vaginal Gel .75% (Metrogel)',
  ClindamycinVaginalCream = 'Clindamycin Vaginal Cream 2% (Cleocin)',
  Metronidazole = 'Metronidazole 500mg (Flagyl)',
  ClotrimazoleVaginalCream = 'Clotrimazole Vaginal Cream',
  FollowUpStdTest = 'Follow Up STD Test',
  PartnerTreatment = 'Partner Treatment',
  LidocaineOintment = 'Lidocaine Ointment',
  VitaminDSupplement = 'Vitamin D',
  MetforminExtendedRelease = 'Metformin Extended Release (ER)',
  AcyclovirCreamHSV = 'Acyclovir Cream (HSV)',
  AcyclovirCream = 'Acyclovir Cream',
  UtiUrineTestKit = 'UTI Urine Test Kit',
  VitaminB12Supplement = 'Vitamin B12 Supplement',
}
